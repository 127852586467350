import Grading from '../../../../../grading';
import GradingHeader from '../../../../../grading-header';
import { Box, Button, Icon, useToast } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from '@lon/shared/hooks';
import { arrDoubleLeftOutlined } from '@lon/shared/icons';
import {
  ResponseFeedbackInput,
  useSaveGradingSessionMutation,
} from '@lon/shared/requests';
import { routes } from '@lon/suit/configs';
import { maxScore } from '@lon/suit/constants';
import { FormProps, ResponseInput } from './duck';

const Form: React.FC<FormProps> = ({
  isDa,
  items,
  defaultValues,
  originalActivityItems,
  studentAssignments,
}) => {
  const { assignmentId } = useParams();
  const [params, setParams] = useQueryParams();
  const currentQuestionNumber = Number(params.questionNumber) || 1;
  const [saveGrades] = useSaveGradingSessionMutation();
  const [savingGrades, setSavingGrades] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const formProviderProps = useForm({
    mode: 'onSubmit',
    defaultValues,
  });
  const originalActivityItemsLength = originalActivityItems?.length || 0;

  const onSubmit = (e: Record<string, string>) => {
    setSavingGrades(true);

    const responses = Object.keys(e)
      .filter((key) => key.startsWith('score_'))
      .map((key) => {
        const responseId = key.replace('score_', '');

        const currentResponse = items.find(
          (response) => response.responseId === responseId
        );

        const isNumeric = !isNaN(parseFloat(e[key]));

        if (!isNumeric) {
          return undefined;
        }

        const score = (Number(e[key]) * maxScore) / 100;

        return {
          reportContainerId: currentResponse?.reportContainerId,
          response_id: responseId,
          score: String(score),
          max_score: String(maxScore),
        };
      })
      .filter(
        (item): item is ResponseInput & { reportContainerId: string } =>
          item !== undefined
      )
      .reduce(
        (acc, responce) => {
          const { reportContainerId, ...rest } = responce;

          return {
            ...acc,
            [reportContainerId]: [
              ...(acc[reportContainerId] ? acc[reportContainerId] : []),
              rest,
            ],
          };
        },
        {} as {
          [key: string]: ResponseInput[];
        }
      );

    const responseFeedbacks = Object.keys(e)
      .filter((key) => key.startsWith('feedback_'))
      .map((key) => {
        const responseId = key.replace('feedback_', '');
        const feedback = e[key];
        const currentResponse = items.find(
          (response) => response.responseId === responseId
        );
        if (!feedback) {
          return undefined;
        }
        return {
          reportContainerId: currentResponse?.reportContainerId,
          responseId: responseId,
          feedback,
        };
      })
      .filter(
        (item): item is ResponseFeedbackInput & { reportContainerId: string } =>
          item !== undefined
      )
      .reduce(
        (acc, responseFeedback) => {
          const { reportContainerId, ...rest } = responseFeedback;

          return {
            ...acc,
            [reportContainerId]: [
              ...(acc[reportContainerId] ? acc[reportContainerId] : []),
              rest,
            ],
          };
        },
        {} as {
          [key: string]: ResponseFeedbackInput[];
        }
      );

    saveGrades({
      variables: {
        sessionsParams: studentAssignments.map((assignment, index) => ({
          sessionId: assignment.id as string,
          userId: assignment.studentId as string,
          responses: responses[`report-${index + 1}`] || [],
          responseFeedbacks: responseFeedbacks[`report-${index + 1}`] || [],
        })),
      },
      onCompleted: (response) => {
        const isUpdated = response?.saveGradingSession?.success;
        if (isUpdated) {
          // Learnosity does not update grades on their side immediately.
          setTimeout(() => {
            setSavingGrades(false);
            toast({
              title: t('manualGrading.successSave'),
              variant: 'success-light',
            });
            navigate(
              generatePath(
                isDa
                  ? routes.classes.reportDashboard.districtAssessment
                  : routes.classes.assignments.view,
                {
                  assignmentId: assignmentId,
                }
              )
            );
          }, 7000);
        } else {
          setSavingGrades(false);
          toast({
            title: t('systemMessages.038'),
            variant: 'error-light',
            isClosable: true,
          });
        }
      },
      onError: () => {
        setSavingGrades(false);
        toast({
          title: t('systemMessages.038'),
          variant: 'error-light',
          isClosable: true,
        });
      },
    });
  };

  useEffect(() => {
    // dispatch resize so that learnosity understands
    // that the initial layout changed
    window.dispatchEvent(new Event('resize'));
  }, []);

  useEffect(() => {
    const answersContainer = document.querySelector('.student-answers');
    const learnosityItems = Array.from(
      answersContainer?.querySelectorAll(
        '.learnosity-item'
      ) as NodeListOf<HTMLDivElement>
    );
    learnosityItems.forEach((item) => {
      const isHiddenQuestion = item.classList.contains('hidden');
      const isCurrentQuestion =
        originalActivityItems?.[currentQuestionNumber - 1]?.id ===
        item.dataset.reference;

      if (!isHiddenQuestion && !isCurrentQuestion) {
        item.classList.add('hidden');
      } else if (isHiddenQuestion && isCurrentQuestion) {
        item.classList.remove('hidden');
      }
    });
  }, [currentQuestionNumber]);

  const changeQuestion = (newQuestionNumber: number) => {
    const content = document.querySelector(`.page-content`);
    content?.scrollTo(0, 0);
    setParams({
      questionNumber: newQuestionNumber,
    });
  };

  return (
    <FormProvider {...formProviderProps}>
      <Box
        as="form"
        display="flex"
        gap="5"
        mt="5"
        onSubmit={formProviderProps.handleSubmit(onSubmit)}
      >
        {items.map((item) => {
          const reportContainer = document.getElementById(
            item.reportContainerId
          );
          const questionContainer = reportContainer
            ?.querySelector(`[id='${item.responseId}']`)
            ?.closest('.learnosity-question-container') as Element;

          if (!questionContainer) {
            return null;
          }

          return createPortal(
            <Grading
              responseId={item.responseId}
              isAutogradable={item.automarkable}
              loading={savingGrades}
            >
              <GradingHeader response={item} />
            </Grading>,
            questionContainer
          ) as ReactElement;
        })}
        <Button
          variant="solid"
          isDisabled={currentQuestionNumber <= 1}
          leftIcon={<Icon as={arrDoubleLeftOutlined} />}
          onClick={() => {
            changeQuestion(currentQuestionNumber - 1);
          }}
        >
          {t('studentAssignmentDetails.previousBtn')}
        </Button>
        <Button
          variant="solid"
          isDisabled={
            originalActivityItemsLength
              ? currentQuestionNumber >= originalActivityItemsLength
              : true
          }
          rightIcon={
            <Icon as={arrDoubleLeftOutlined} transform="rotate(180deg)" />
          }
          onClick={() => {
            changeQuestion(currentQuestionNumber + 1);
          }}
        >
          {t('studentAssignmentDetails.nextBtn')}
        </Button>
        <Button
          variant="solid"
          type="submit"
          isDisabled={!formProviderProps.formState.isDirty || savingGrades}
          isLoading={savingGrades}
        >
          {t('studentAssignmentDetails.save')}
        </Button>
      </Box>
    </FormProvider>
  );
};

export default Form;
